import { Box, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box my={5}>
      <Typography variant="h3" color={"secondary"} textAlign={"center"}>
        &copy;2024 Poochi - All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Footer;
