import { Box, Button, Grid, Stack, styled, useTheme } from "@mui/material";
import React, { useRef } from "react";
import data from "../utils/index.json";
import html2canvas from "html2canvas";
import CasinoIcon from "@mui/icons-material/Casino";
import DownloadIcon from "@mui/icons-material/Download";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const PoochiBody = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const PoochiMisc = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const PoochiOutfit = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const PoochiTop = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const PoochiWings = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  zIndex: -1,
}));

const AvatarLoader = (props) => {
  const theme = useTheme();
  const boxRef = useRef();

  const handleGenerate = () => {
    const randomPoochi = {
      background: getRandomId(data.background),
      body: getRandomId(data.body),
      MISC: getRandomId(data.MISC),
      outfit: getRandomId(data.outfit),
      top: getRandomId(data.top),
      wings: getRandomId(data.wings),
    };

    function getRandomId(obj) {
      const keys = Object.keys(obj);
      const randomIndex = Math.floor(Math.random() * keys.length);
      return keys[randomIndex];
    }

    props.setPoochi(randomPoochi);
  };

  const download = () => {
    html2canvas(boxRef.current).then((canvas) => {
      const link = document.createElement("a");
      link.download = "download.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };
  const handleReset = () => {
    props.setPoochi({
      background: "BG2",
      body: "BODY12",
      MISC: "",
      outfit: "",
      top: "",
      wings: "",
    });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        {/* Background */}
        {data.background[props.poochi.background] ? (
          <Box
            ref={boxRef}
            sx={{
              borderRadius: theme.spacing(1),
              height: "450px",
              width: "100%",
              backgroundImage: `url(${require(`../assets/images/BACKGROUNDS/${
                data.background[props.poochi.background]
              }`)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              position: "relative",
              zIndex: 100,

              [theme.breakpoints.down("md")]: {
                height: "350px",
              },
            }}
          >
            {/* Body */}
            {data.body[props.poochi.body] ? (
              <PoochiBody
                component={"img"}
                src={require(`../assets/images/BODY/${
                  data.body[props.poochi.body]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Misc */}
            {data.MISC[props.poochi.MISC] ? (
              <PoochiMisc
                component={"img"}
                src={require(`../assets/images/MISC/${
                  data.MISC[props.poochi.MISC]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Outfit */}
            {data.outfit[props.poochi.outfit] ? (
              <PoochiOutfit
                component={"img"}
                src={require(`../assets/images/OUTFIT/${
                  data.outfit[props.poochi.outfit]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Top */}
            {data.top[props.poochi.top] ? (
              <PoochiTop
                component={"img"}
                src={require(`../assets/images/TOP/${
                  data.top[props.poochi.top]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Wings */}
            {data.wings[props.poochi.wings] ? (
              <PoochiWings
                component={"img"}
                src={require(`../assets/images/WINGS/${
                  data.wings[props.poochi.wings]
                }`)}
              />
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12} mt={5}>
        <Stack direction={"column"} gap={3}>
          <Button
            sx={{
              height: 64,
              fontSize: 24,
              [theme.breakpoints.down("md")]: {
                height: 48,
                fontSize: 16,
              },
            }}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            startIcon={<RotateLeftIcon />}
            onClick={handleReset}
          >
            Reset Poochi
          </Button>
          <Button
            sx={{
              height: 64,
              fontSize: 24,
              [theme.breakpoints.down("md")]: {
                height: 48,
                fontSize: 16,
              },
            }}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            onClick={handleGenerate}
            startIcon={<CasinoIcon />}
          >
            Generate Random
          </Button>
          <Button
            sx={{
              height: 64,
              fontSize: 24,
              [theme.breakpoints.down("md")]: {
                height: 48,
                fontSize: 16,
              },
            }}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            onClick={download}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AvatarLoader;
