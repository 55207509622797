import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import AvatarLoader from "../components/AvatarLoader";
import Editor from "../components/Editor";

const EditorPage = () => {
  const [poochi, setPoochi] = useState({
    background: "BG2",
    body: "BODY12",
    MISC: "",
    outfit: "",
    top: "",
    wings: "",
  });
  useEffect(() => {
    console.log("poochi", poochi);
  }, [poochi]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Grid container spacing={5} justifyContent={"center"}>
      <Grid item xs={12} md={7} order={isSmallScreen ? 2 : 1}>
        <Editor poochi={poochi} setPoochi={setPoochi} />
      </Grid>

      <Grid item xs={12} md={4} order={isSmallScreen ? 1 : 2}>
        <AvatarLoader poochi={poochi} setPoochi={setPoochi} />
      </Grid>
    </Grid>
  );
};

export default EditorPage;
