import { Box, Grid, Typography, styled, useTheme } from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import data from "../utils/index.json";
import BlockIcon from "@mui/icons-material/Block";

const PageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.secondary,
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 800,
  color: "#fdf6e7",
  textTransform: "uppercase",
  textAlign: "center",

  [theme.breakpoints.down("md")]: {
    fontSize: theme.spacing(3),
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  color: "#fdf6e7",
  textTransform: "uppercase",

  [theme.breakpoints.down("md")]: {
    fontSize: theme.spacing(2),
  },
}));

const MiniBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
}));

const MiniBox = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: theme.spacing(1),
  background: theme.palette.secondary.main,
  border: `3px solid ${theme.palette.primary.light}`,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    width: 40,
    height: 40,
  },

  ":hover": {
    border: `3px solid ${theme.palette.secondary.main}`,
    background: theme.palette.primary.light,
  },

  ":hover svg": {
    color: theme.palette.secondary.main,
  },
}));

const MinBoxImg = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  borderRadius: theme.spacing(1),
}));

function SampleNextArrow(props) {
  const theme = useTheme();

  const { className, style, onClick } = props;
  return (
    <Box
      className={className}
      sx={{
        // background: theme.palette.primary.light,
        background: "linear-gradient(to left, white, rgba(255,255,255,0))",
        borderRadius: "5px",
        width: "20px",
        height: "80%",
        color: "white",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
          width: "10px",
          height: "60%",
        },

        ":hover": {
          background: theme.palette.primary.light,
        },

        "&.slick-next:before": {
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.secondary.main,
        },
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const theme = useTheme();

  const { className, style, onClick } = props;
  return (
    <Box
      className={className}
      sx={{
        // background: theme.palette.primary.light,
        background: "linear-gradient(to right, white, rgba(255,255,255,0))",

        width: "20px",
        borderRadius: "5px",
        height: "80%",
        color: "white",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
          width: "10px",
          height: "60%",
        },

        ":hover": {
          background: theme.palette.primary.light,
        },

        "&.slick-prev:before": {
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.secondary.main,
        },
      }}
      onClick={onClick}
    />
  );
}

const Editor = (props) => {
  const theme = useTheme();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <PageWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainTitle>Create your meme</MainTitle>
        </Grid>

        {/* Background */}
        <Grid item xs={12}>
          <SubTitle>Background</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.background).length > 6 ? (
                <Slider {...settings}>
                  {Object.entries(data.background).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setPoochi({
                            ...props.poochi,
                            background: key,
                          })
                        }
                        sx={{
                          background:
                            props.poochi.background === key &&
                            theme.palette.primary.light,
                          border:
                            props.poochi.background === key &&
                            `3px solid ${theme.palette.secondary.main}`,
                        }}
                      >
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/BACKGROUNDS/${value}`)}
                        />
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.background).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setPoochi({
                          ...props.poochi,
                          background: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.poochi.background === key &&
                          theme.palette.primary.light,
                        border:
                          props.poochi.background === key &&
                          `3px solid ${theme.palette.secondary.main}`,
                      }}
                    >
                      <MinBoxImg
                        component="img"
                        src={require(`../assets/images/BACKGROUNDS/${value}`)}
                      />
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Body */}
        <Grid item xs={12}>
          <SubTitle>Body</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.body).length > 6 ? (
                <Slider {...settings}>
                  {Object.entries(data.body).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setPoochi({
                            ...props.poochi,
                            body: key,
                          })
                        }
                        sx={{
                          background:
                            props.poochi.body === key &&
                            theme.palette.primary.light,
                          border:
                            props.poochi.body === key &&
                            `3px solid ${theme.palette.secondary.main}`,
                        }}
                      >
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/BODY/${value}`)}
                        />
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.background).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setPoochi({
                          ...props.poochi,
                          body: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.poochi.body === key &&
                          theme.palette.primary.light,
                        border:
                          props.poochi.body === key &&
                          `3px solid ${theme.palette.secondary.main}`,
                      }}
                    >
                      <MinBoxImg
                        component="img"
                        src={require(`../assets/images/MISC/${value}`)}
                      />
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Misc */}
        <Grid item xs={12}>
          <SubTitle>Misc</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.MISC).length > 6 ? (
                <Slider {...settings}>
                  {Object.entries(data.MISC).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setPoochi({
                            ...props.poochi,
                            MISC: key || "",
                          })
                        }
                        sx={{
                          background:
                            props.poochi.MISC === key &&
                            theme.palette.primary.light,
                          border:
                            props.poochi.MISC === key &&
                            `3px solid ${theme.palette.secondary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/MISC/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.light,
                            }}
                            onClick={(e) =>
                              props.setPoochi({
                                ...props.poochi,
                                MISC: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.MISC).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setPoochi({
                          ...props.poochi,
                          MISC: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.poochi.MISC === key &&
                          theme.palette.primary.light,
                        border:
                          props.poochi.MISC === key &&
                          `3px solid ${theme.palette.secondary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/MISC/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setPoochi({
                              ...props.poochi,
                              MISC: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Outfit */}
        <Grid item xs={12}>
          <SubTitle>Outfit</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.outfit).length > 6 ? (
                <Slider {...settings}>
                  {Object.entries(data.outfit).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setPoochi({
                            ...props.poochi,
                            outfit: key || "",
                          })
                        }
                        sx={{
                          background:
                            props.poochi.outfit === key &&
                            theme.palette.primary.light,
                          border:
                            props.poochi.outfit === key &&
                            `3px solid ${theme.palette.secondary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/OUTFIT/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.light,
                            }}
                            onClick={(e) =>
                              props.setPoochi({
                                ...props.poochi,
                                outfit: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.outfit).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setPoochi({
                          ...props.poochi,
                          outfit: key,
                        })
                      }
                      sx={{
                        background:
                          props.poochi.outfit === key &&
                          theme.palette.primary.light,
                        border:
                          props.poochi.outfit === key &&
                          `3px solid ${theme.palette.secondary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/OUTFIT/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setPoochi({
                              ...props.poochi,
                              outfit: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Top */}
        <Grid item xs={12}>
          <SubTitle>Top</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.top).length > 6 ? (
                <Slider {...settings}>
                  {Object.entries(data.top).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setPoochi({
                            ...props.poochi,
                            top: key || "",
                          })
                        }
                        sx={{
                          background:
                            props.poochi.top === key &&
                            theme.palette.primary.light,
                          border:
                            props.poochi.top === key &&
                            `3px solid ${theme.palette.secondary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/TOP/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.light,
                            }}
                            onClick={(e) =>
                              props.setPoochi({
                                ...props.poochi,
                                top: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.top).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setPoochi({
                          ...props.poochi,
                          top: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.poochi.top === key &&
                          theme.palette.primary.light,
                        border:
                          props.poochi.top === key &&
                          `3px solid ${theme.palette.secondary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/TOP/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setPoochi({
                              ...props.poochi,
                              top: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Wings */}
        <Grid item xs={12}>
          <SubTitle>Wings</SubTitle>
          <Grid container my={1}>
            <Grid item xs={12}>
              {Object.entries(data.wings).length > 6 ? (
                <Slider {...settings}>
                  {Object.entries(data.wings).map(([key, value]) => (
                    <MiniBoxWrapper key={key}>
                      <MiniBox
                        onClick={(e) =>
                          props.setPoochi({
                            ...props.poochi,
                            wings: key || "",
                          })
                        }
                        sx={{
                          background:
                            props.poochi.wings === key &&
                            theme.palette.primary.light,
                          border:
                            props.poochi.wings === key &&
                            `3px solid ${theme.palette.secondary.main}`,
                        }}
                      >
                        {value ? (
                          <MinBoxImg
                            component="img"
                            src={require(`../assets/images/WINGS/${value}`)}
                          />
                        ) : (
                          <BlockIcon
                            sx={{
                              color: theme.palette.primary.main,
                            }}
                            onClick={(e) =>
                              props.setPoochi({
                                ...props.poochi,
                                wings: "",
                              })
                            }
                          />
                        )}
                      </MiniBox>
                    </MiniBoxWrapper>
                  ))}
                </Slider>
              ) : (
                <MiniBoxWrapper>
                  {Object.entries(data.wings).map(([key, value]) => (
                    <MiniBox
                      onClick={(e) =>
                        props.setPoochi({
                          ...props.poochi,
                          wings: key || "",
                        })
                      }
                      sx={{
                        background:
                          props.poochi.wings === key &&
                          theme.palette.primary.light,
                        border:
                          props.poochi.wings === key &&
                          `3px solid ${theme.palette.secondary.main}`,
                      }}
                    >
                      {value ? (
                        <MinBoxImg
                          component="img"
                          src={require(`../assets/images/WINGS/${value}`)}
                        />
                      ) : (
                        <BlockIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                          onClick={(e) =>
                            props.setPoochi({
                              ...props.poochi,
                              wings: "",
                            })
                          }
                        />
                      )}
                    </MiniBox>
                  ))}
                </MiniBoxWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Editor;
