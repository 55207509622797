import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AppBar, Box, Button, Stack, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import Logo from "../assets/images/CIRCLEDOG.png";
import { Link } from "react-router-dom";

const StickyGrid = styled(AppBar)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1000,
  backgroundColor: "transparent",
  boxShadow: "none",
  margin: "20px 0 30px",
  padding: "0 50px",

  [theme.breakpoints.down("md")]: {
    padding: "0 ",
  },
}));

const Header = () => {
  const theme = useTheme();
  return (
    <StickyGrid>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          sx={{
            height: 64,
            fontSize: 24,

            [theme.breakpoints.down("md")]: {
              height: 48,
              fontSize: 16,
            },
          }}
          variant="contained"
          size="large"
          color="secondary"
          component={Link}
          startIcon={<KeyboardBackspaceIcon />}
          target="_blank"
          to={"https://poochisol.xyz/"}
        >
          Go back home
        </Button>

        <Box
          component={"img"}
          src={Logo}
          alt="poochi"
          sx={{
            height: "80px",
            [theme.breakpoints.down("md")]: {
              height: 56,
            },
          }}
        />
      </Stack>
    </StickyGrid>
  );
};

export default Header;
