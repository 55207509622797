// In App.js
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { baseTheme } from "./assets/global/Theme-variable";
import Layout from "./layout";
import EditorPage from "./page/EditorPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <EditorPage /> },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  },
]);

const App = () => {
  return (
    <ThemeProvider theme={baseTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
