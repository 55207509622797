import { styled } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import BackgroundImg from "../assets/images/BG.png";
import Header from "./Header";
import Footer from "./Footer";

const MainWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "100vh",
  overflow: "hidden",
  margin: "0 auto",
  maxWidth: "1440px",
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const PageWrapper = styled("div")(({ theme }) => ({
  backgroundImage: `url(${BackgroundImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("md")]: {
    backgroundPosition: "left center",
  },
}));

const Layout = () => {
  return (
    <PageWrapper>
      <MainWrapper>
        <Header />
        <Outlet />
        <Footer />
      </MainWrapper>
    </PageWrapper>
  );
};

export default Layout;
